<template>
  <div>
    <!-- Filter Section -->
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- origin  -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.monitoringChRate.singular.origin')"
              label-for="origin-code"
            >
                <v-select
                    inputId="origin-code"
                    class="select-size-sm"
                    v-model="form.originCode"
                    :options="LOV.origin"
                    :reduce="field => field.code"
                    label="origin"
                />
            </b-form-group>
          </b-col>
          <!-- destination  -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.monitoringChRate.singular.destination')"
              label-for="destination-code"
            >
                <v-select
                    inputId="destination-code"
                    class="select-size-sm"
                    v-model="form.destinationCode"
                    :options="LOV.destination"
                    :reduce="field => field.destination"
                    label="destination"
                />
            </b-form-group>
          </b-col>
          <!-- button search/filter  -->
          <b-col cols="12" md="3">
            <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <div class="mt-1 mb-1 d-flex justify-content-end">
      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">
          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>
          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>
        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">
      <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ $t('apps.monitoringChRate.moduleName') }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
        </b-card-sub-title>
      </b-card-body>

        <b-table
            id="refListTable"
            ref="refListTable"
            no-provider-paging
            no-provider-filtering
            :filter-included-fields="columnToBeFilter"
            :fields="tableColumns"
            :items="itemLists"
            :filter="searchQuery"
            responsive
            primary-key="id"
            show-empty
            :busy="isBusy"
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
          >
            <template #table-busy>
              <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
                <div class="mb-1 text">
                  <b-spinner class="align-middle"/>
                </div>
                <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
              </div>
            </template>

            <!-- Column: amount_dl_1 -->
            <template #cell(amount_dl_1)="{ item }">
              <span :class="['text-right', 'text-nowrap']">
                <div>
                  {{ formatCurrency(item.amount_dl_1,false) }}
                </div>
              </span>
            </template>

            <!-- Column: amount_dl_2 -->
            <template #cell(amount_dl_2)="{ item }">
              <span :class="['text-right', 'text-nowrap']">
                <div>
                  {{ formatCurrency(item.amount_dl_2,false) }}
                </div>
              </span>
            </template>

            <!-- Column: amount_dl_3 -->
            <template #cell(amount_dl_3)="{ item }">
              <span :class="['text-right', 'text-nowrap']">
                <div>
                  {{ formatCurrency(item.amount_dl_3,false) }}
                </div>
              </span>
            </template>

            <!-- Column: amount_dl_4 -->
            <template #cell(amount_dl_4)="{ item }">
              <span :class="['text-right', 'text-nowrap']">
                <div>
                  {{ formatCurrency(item.amount_dl_4,false) }}
                </div>
              </span>
            </template>

            <!-- Column: amount_fud -->
            <template #cell(amount_fud)="{ item }">
              <span :class="['text-right', 'text-nowrap']">
                <div>
                  {{ formatCurrency(item.amount_fud,false) }}
                </div>
              </span>
            </template>

            <!-- Column: amount_ffw -->
            <template #cell(amount_ffw)="{ item }">
              <span :class="['text-right', 'text-nowrap']">
                <div>
                  {{ formatCurrency(item.amount_ffw,false) }}
                </div>
              </span>
            </template>

            <!-- Column: amount_ap -->
            <template #cell(amount_ap)="{ item }">
              <span :class="['text-right', 'text-nowrap']">
                <div>
                  {{ formatCurrency(item.amount_ap,false) }}
                </div>
              </span>
            </template>

        </b-table>


    </b-card>

  </div>
</template>
<script>
import vSelect from 'vue-select'
import { ref, onMounted } from '@vue/composition-api'
// import useHttp from '@/comp-functions/useHttp'
import useListTable from '@/comp-functions/useListTable'
import { formatCurrency, unformatNumber } from '@/utils/formatter'
import useFormResource from '@/comp-functions/useFormResource'


import {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BSpinner,
    vSelect
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const periodReportText = ref()
    const { get } = useFormResource({ url: '' })


    // isian yang bakalan ditampung pas milih
    const form = ref({
        originCode: '',
        destinationCode: '',
    })

    // filter
    const columnToBeFilter = []
    const tableColumns = [
      { key: 'origin', label: 'Origin', thClass: 'text-nowrap',tdClass: 'font-weight-bolder text-nowrap' },
      { key: 'destination', label: 'Destination'},
      { key: 'amount_dl_1', label: 'Delivery Min', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'amount_dl_2', label: 'Delivery 3-50Kg', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'amount_dl_3', label: 'Delivery 50-300Kg', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'amount_dl_4', label: 'Delivery > 300Kg', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'amount_fud', label: 'Transit', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'amount_ffw', label: 'Forward', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'amount_ap', label: 'APO', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
    ]

    //data-data pilihan
    const LOV = ref({
      origin: [],
      destination: []
    })

    const getOrigin = async () => {
      LOV.value.origin = await get({ url: `value/origin-ch-rate`})
    }

    const getDestination = async () => {
      LOV.value.destination = await get({ url: `value/destination-ch-rate`})
    }

    onMounted(async () => {
      getOrigin()
      getDestination()
      const params = [
        `origin=''`,
        `destination=''`
      ]
      const url = `reports/monitoring/ch-rate?${params.join('&')}`
      await fetchLists(url)

    })

    return {
        form,
        LOV,
        columnToBeFilter,
        tableColumns,
        searchQuery,
        periodReportText,
        isBusy,
        itemLists,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        perPageOptions,
        isSortDirDesc,
        refListTable,
        statusFilter,
        fetchLists,
        formatCurrency
    }
  },
  methods: {
    getReport () {
    const params = [
           `origin=${this.form.originCode}`,
           `destination=${this.form.destinationCode}`,
         ]

      const url = `reports/monitoring/ch-rate?${ params.join('&') }`
      this.fetchLists(url)
    },
    print () {
      window.print()
    },
   exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists;

        const headerTitle = this.tableColumns.map(column => column.label);
        const headerVal = this.tableColumns.map(column => column.key);

        const data = this.formatJson(headerVal, list).map(row => {
          return row.map(cell => {
            return unformatNumber(cell);
          });
        });

        // Avoid null value, change it to empty string
        const validData = data.map(row => row.map(cell => cell != null ? cell : ''));

        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            [this.$t('apps.reportCashBank.singular.cashBankReport'), '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data: validData,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } }
          ],
          filename: `${this.$t('apps.reportCashBank.singular.cashBankReport')} ${this.periodReportText}`,
          autoWidth: false,
          bookType: 'xlsx'
        });
      });
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  },
}

</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
